import { components } from "~/openapi/schema";
import { motion, useScroll, useTransform } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import CkEditorContent from "~/components/content/ck-editor-content";
import { cn } from "~/lib/utils";
import { strapiRepository } from "~/openapi/strapiRepository";
import { PhotoProvider, PhotoView } from "react-photo-view";

export const Timeline = ({
  timeline,
  className,
}: {
  timeline: components["schemas"]["SharedTimelineComponent"];
  className?: string;
}) => {
  const { getImageUrl } = strapiRepository;
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setHeight(rect.height);
    }
  }, [ref]);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: [`start 30%`, "end 75%"],
  });

  const heightTransform = useTransform(scrollYProgress, [0, 1], [0, height]);
  const opacityTransform = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  return (
    <div className={className} ref={containerRef}>
      <div ref={ref} className="relative mx-auto mt-20 max-w-7xl">
        {timeline.items?.map((item, index) => (
          <div
            key={index}
            className={cn("flex justify-start md:gap-10", {
              "mb-20": timeline.items && index !== timeline.items.length - 1,
            })}
          >
            <div className="sticky top-40 z-40 flex max-w-xs flex-col items-start self-start md:w-full md:flex-row lg:max-w-sm">
              <div className="absolute left-3 flex h-10 w-10 items-center justify-center rounded-full bg-white dark:bg-black md:left-3">
                <div className="h-4 w-4 rounded-full border border-neutral-300 bg-neutral-200 p-2 dark:border-neutral-700 dark:bg-neutral-800" />
              </div>
              <div className={"hidden md:block md:pl-20"}>
                <h3 className="text-3xl font-bold md:text-5xl">{item.title}</h3>
                <p className={"mt-2 text-xl text-foreground/50 md:text-3xl"}>
                  {item.subtitle}
                </p>
              </div>
            </div>

            <div className="relative w-full pl-20 pr-4 md:pl-4">
              <div className={"md:hidden md:pl-20"}>
                <h3 className="text-3xl font-bold md:text-5xl">{item.title}</h3>
                <p className={"mb-2 text-xl text-foreground/50 md:text-3xl"}>
                  {item.subtitle}
                </p>
              </div>
              {item.content && <CkEditorContent htmlContent={item.content} />}

              {item.gallery && item.gallery?.length > 0 && (
                <PhotoProvider
                  overlayRender={(params) => {
                    if (!item.gallery?.[params.index].caption) return null;
                    return (
                      <div
                        className={
                          "absolute bottom-0 z-50 flex h-12 w-full items-center justify-center bg-foreground/50 text-center text-background"
                        }
                      >
                        {item.gallery[params.index].caption}
                      </div>
                    );
                  }}
                >
                  <div className={"mt-8 flex flex-wrap justify-start gap-2"}>
                    {item.gallery.map((image) => (
                      <PhotoView key={image.id} src={getImageUrl(image)}>
                        <img
                          src={getImageUrl(image)}
                          alt={image.alternativeText}
                          className={
                            "size-32 cursor-pointer object-cover object-center md:size-28 lg:size-32 xl:size-44"
                          }
                        />
                      </PhotoView>
                    ))}
                  </div>
                </PhotoProvider>
              )}
            </div>
          </div>
        ))}
        <div
          style={{
            height: height + "px",
          }}
          className="absolute left-8 top-0 w-[2px] overflow-hidden bg-[linear-gradient(to_bottom,var(--tw-gradient-stops))] from-transparent from-[0%] via-neutral-200 to-transparent to-[99%] [mask-image:linear-gradient(to_bottom,transparent_0%,black_10%,black_90%,transparent_100%)] dark:via-neutral-700 md:left-8"
        >
          <motion.div
            style={{
              height: heightTransform,
              opacity: opacityTransform,
            }}
            className="absolute inset-x-0 top-0 w-[2px] rounded-full bg-gradient-to-t from-purple-500 from-[0%] via-blue-500 via-[10%] to-transparent"
          />
        </div>
      </div>
    </div>
  );
};
