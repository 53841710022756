import { json } from "@remix-run/node";
import { RemixHandle } from "~/lib/remix";
import { strapiRepository } from "~/openapi/strapiRepository";
import invariant from "tiny-invariant";
import { useLoaderData } from "@remix-run/react";
import CkEditorContent from "~/components/content/ck-editor-content";
import { Timeline } from "~/components/content/timeline";

interface LoaderFunctionArgs {
  params: {
    pageSlug: string;
  };
}

export async function loader({ params }: LoaderFunctionArgs) {
  const { getPageBySlug } = strapiRepository;

  invariant(params.pageSlug, "Page slug is required");

  const { data: page, error } = await getPageBySlug(params.pageSlug);

  if (error) {
    if (error.error?.status === 404) {
      throw new Response(null, {
        status: 404,
        statusText: "Not Found",
      });
    }
    throw new Error("Failed to load page");
  }
  if (!page || !page.data) {
    throw new Error("Page data not found");
  }

  return json({ page: page.data });
}

export const handle: RemixHandle<typeof loader, LoaderFunctionArgs> = {
  breadcrumbTitle: ({ data }) => data.page.title ?? "Stránka",
};

export default function Page() {
  const { page } = useLoaderData<typeof loader>();

  return (
    <div className={"container"}>
      <h1 className={"my-8 text-center text-5xl font-extrabold"}>
        {page.title}
      </h1>
      <div className={"flex flex-col gap-10"}>
        {page.dynamic_content?.map((item, index) => {
          switch (item.__component) {
            case "shared.timeline":
              return (
                <Timeline
                  className={"w-full md:px-10"}
                  timeline={item}
                  key={index}
                />
              );
            case "shared.content":
              if (!item.ckeditor_content) return null;
              return (
                <CkEditorContent
                  className={"w-full md:px-10"}
                  htmlContent={item.ckeditor_content}
                  key={index}
                ></CkEditorContent>
              );
          }
        })}
      </div>
    </div>
  );
}
